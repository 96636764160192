import React, { Component } from 'react';

class ContactUsText extends Component {

    render() {
        return (
            <div>
                <div className="content-subtitle bold-text top-title">Get in Touch with Us</div>
                <p className="long-content">Perpetuum IT is dedicated to forging connections that empower tech professionals and enhance business solutions. Whether you're looking to shape your career or enhance your company's capabilities, we're here to facilitate the conversation.</p>

                <div className="content-subtitle bold-text">For Independent Tech Professionals</div>
                <p className="long-content">Are you a developer seeking to advance your career with exciting projects? We're looking for independent associates who aspire to grow and succeed. Explore how you can join our network of talent.</p>
                <p className="long-content">Reach out to us at <a className="mail-link" href="mailto:careers@perpetuumit.com">careers@perpetuumit.com</a>.</p>

                <div className="content-subtitle bold-text">For Clients and Business Partners</div>
                <p className="long-content">If your company is on a quest for top-notch IT services, or you're considering a cooperative venture, we're eager to discuss how our adept talent can fulfill your requirements.</p>
                <p className="long-content">Connect with our sales team at <a className="mail-link" href="mailto:sales@perpetuumit.com">sales@perpetuumit.com</a>.</p>

                <div className="content-subtitle bold-text">For Recruitment Partners</div>
                <p className="long-content">Have access to a network of developers ready for challenging and rewarding projects? We value partnerships that help us discover skilled professionals eager to collaborate.</p>
                <p className="long-content">Let's work together: <a className="mail-link" href="mailto:recruitment@perpetuumit.com">recruitment@perpetuumit.com</a>.</p>

                <div className="content-subtitle bold-text">For Marketing and Project Opportunities</div>
                <p className="long-content">Interested in driving project opportunities our way or spreading the word about Perpetuum IT? Your marketing expertise can help us reach new heights and broaden our collaborative horizons.</p>
                <p className="long-content">Join our marketing efforts at <a className="mail-link" href="mailto:marketing@perpetuumit.com">marketing@perpetuumit.com</a>.</p>

                <div className="content-subtitle bold-text">General Inquiries</div>
                <p className="long-content">For all other inquiries, whether you have questions about our services or you're seeking more information about career opportunities, we're here to provide the support you need.</p>
                <p className="long-content">Contact us at <a className="mail-link" href="mailto:support@perpetuumit.com">support@perpetuumit.com</a>.</p>
            </div>
        )
    }

}

export default ContactUsText;