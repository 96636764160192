import React, { useEffect } from 'react';
import ContentPageHeader from "components/sections/Content_Page_Header.js";
import Organization from "components/seo/Organization.js";
import Breadcrumb from "components/seo/Breadcrumb.js";
import ContactUsText from "components/text/Contact_Us_Text.js";
import MetaTags from "components/seo/Meta_Tags.js";
import Footer from "components/sections/Footer.js";
import Consent from 'utils/Consent.js';

const ContactUs = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
        Consent.addThirdParty();
    }, []); 

    return (
        <>
            <MetaTags
                title="Contact Us - PerpetuumIT.com"
                description="Get in touch for premier IT services and expert consultation. Partner with Perpetuum IT for your tech needs."
                url="/contact-us"
            />
            <Organization />
            <Breadcrumb
                title="Contact Us - PerpetuumIT.com"
                path="/contact-us"
            />
            <div id="main">
                <div id="content">
                    <ContentPageHeader />
                    <section className="content-page-section">
                        <div>
                            <div className="title-container">
                                <h1 className="page-title">Contact Us</h1>
                            </div>
                            <div className="page-container">
                                <ContactUsText />
                            </div>
                        </div>
                    </section>
                </div>
                <Footer />
            </div>
        </>
    );
};

export default ContactUs;