import React from 'react';

class Organization extends React.Component {

    render() {
        
        return (
            <script type="application/ld+json">
                {`{
                "@context": "https://schema.org",
                "@type": "Organization",
                "name": "Perpetuum IT",
                "description": "Lead with Perpetuum IT's advanced IT outsourcing and consultancy. Tailored solutions for your tech success.",
                "url": "https://perpetuumit.com",
                "logo": "https://perpetuumit.com/images/home-icon.png",
                "sameAs" : [
                    "https://www.linkedin.com/company/perpetuum-it",
                    "https://www.facebook.com/perpetuum.it",
                    "https://twitter.com/perpetuum_it",
                    "https://www.instagram.com/perpetuum_it",
                    "https://www.upwork.com/agencies/perpetuumit/"
                 ]
   }`}
           </script>
        )
    }
}

export default Organization;