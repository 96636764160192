import React, { Component } from 'react';

class PrivacyPolicyText extends Component {

    render() {
        return (
            <div>
                <div className="content-subtitle bold-text top-title">Our Commitment to Your Privacy</div>
                <p className="long-content">Protecting your private information is a responsibility we take seriously at Perpetuum IT. We understand the importance of privacy in the digital age and are dedicated to safeguarding your personal data.</p>
                <p className="long-content">This document outlines the nature of the information we collect, how it is used, and the measures in place to protect it. Our goal is to ensure transparency and build trust with all our website visitors and partners.</p>
                
                <div className="content-subtitle bold-text">Information We Collect</div>
                <p className="long-content">When you engage with our services, we collect personal details that you voluntarily provide. This includes, but is not limited to, your name, email address, and the name of your company.</p>
                <p className="long-content">Additionally, as you use our website, we automatically collect data related to your visit, such as your IP address. This is a standard practice for websites to ensure optimal functionality and security.</p>
                
                <div className="content-subtitle bold-text">Using Your Personal Data</div>
                <p className="long-content">The information you share with us enables a tailored response to your queries and allows for efficient communication. We value the relationship with our clients and strive to provide personalized interactions.</p>
                <p className="long-content">Your IP address is used to gather broad demographic information, monitor the level of activity on our site, and provide a secure and optimized user experience.</p>
                
                <div className="content-subtitle bold-text">Protection of Your Data</div>
                <p className="long-content">We implement a variety of security measures to uphold the safety of your personal information. These measures are in place to prevent unauthorized access, alteration, disclosure, or destruction of your data.</p>
                <p className="long-content">Rest assured, your data is handled with the utmost care and is accessible only to authorized personnel who need it to perform their job functions.</p>
                
                <div className="content-subtitle bold-text">GDPR Privacy Rights</div>
                <p className="long-content">The General Data Protection Regulation (GDPR) grants you certain rights regarding your personal data. We are fully committed to facilitating your rights to access, correct, and erase your personal information.</p>
                <p className="long-content">If you wish to enact your rights or have any inquiries regarding your data, please feel free to reach out to us directly.</p>
                
                <div className="content-subtitle bold-text">California Privacy Rights</div>
                <p className="long-content">Under the CCPA, California residents have rights to access and request deletion of their personal data. Residents may also opt out of the sale of their data, if applicable.</p>
                <p className="long-content">To exercise these rights, please reach out to us. We are committed to your privacy rights and assure no discrimination for exercising them.</p>

                <div className="content-subtitle bold-text">Company Contact Information</div>
                <p className="long-content">PerpetuumIT.com is the digital presence of Perpetuum IT S.R.L. We are a registered company in the vibrant heart of Costa Rica, committed to providing exceptional IT services globally.</p>
                <p className="long-content">You can find us at our registered address: DE SODA TAPIA 150 METROS ESTE, EDIFICIO DE ASG COLOR TERRACOTA, OFICINA 1, SAN JOSE, COSTA RICA. Our registration number is 3102849718.</p>
                
                <div className="content-subtitle bold-text">Data Protection Officer</div>
                <p className="long-content">For any questions related to the processing of your personal data, or to exercise your rights under the data protection regulations, you may directly contact our Data Protection Officer.</p>
                <p className="long-content">Send your inquiries to <a className="mail-link" href="mailto:dpo@perpetuumit.com">dpo@perpetuumit.com</a> and our Data Protection Officer will address your concerns with the attention and discretion they deserve.</p>
                
                <div className="content-subtitle bold-text">Updates to Our Privacy Policy</div>
                <p className="long-content">As regulatory frameworks evolve and our business grows, we may make amendments to this Privacy Policy. Such changes will be posted on this page and, where appropriate, notified to you by email.</p>
                <p className="long-content">We encourage you to review this policy periodically to be informed of how we are committed to protecting your information and respecting your privacy.</p>

                <div className="content-subtitle bold-text">Cookie Policy</div>
    <p className="long-content">Our website uses cookies to enhance your browsing experience and to gather analytical data about site usage. In compliance with the General Data Protection Regulation (GDPR), we inform you about the cookies we use and their purposes.</p>
    
    <div className="content-subtitle">Types of Cookies We Use</div>
        <p className="long-content bold-text">Google Analytics Cookies:</p>
            <ul className="cookies-list">
                <li><span className="bold-text">_ga:</span> This cookie is set by Google Analytics. It is used to calculate visitor, session, and campaign data and keep track of site usage for the site's analytics report. The cookie stores information anonymously and assigns a randomly generated number to identify unique visitors.</li>
                <li><span className="bold-text">_ga_HBPQSDV90G:</span> This cookie is also set by Google Analytics and is used to persist session state.</li>
            </ul>
    
    <p className="long-content">These cookies are non-essential for the functioning of our website. They help us understand how our visitors interact with the website, enabling us to improve user experience and service delivery.</p>
    <p className="long-content">For more information on how Google uses data when you use our site, please visit <a className="legal-link" href="https://www.google.com/policies/privacy/partners/">Google's policy page</a>.</p>
    </div>

        )
    }

}

export default PrivacyPolicyText;